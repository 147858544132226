<template>
  <div class="mx-3">
    <b-row class="mb-2">
      <b-form @submit="onSubmit" inline>
        <b-form-group>
          <b-form-file
            v-model="form.file"
            accept=".xls, .xlsx, .csv"
            ref="file"
          >
          </b-form-file>
        </b-form-group>
        <b-button
          ref="submitButton"
          style="font-size: 1rem !important"
          class="ml-2 btn-sm"
          type="submit"
          :disabled="!form.file"
        >
          <feather-icon size="15" icon="PlusIcon" class="mr-50" />
          <span style="font-size: 15px">Agregar Adjunto</span>
        </b-button>
        <button
          class="btn btn-sm button-primary ml-2"
          @click="exportToExcel"
          type="button"
        >
          <feather-icon size="15" icon="DownloadIcon" class="mr-50" />
          <span style="font-size: 15px">Descargar CP</span>
        </button>
      </b-form>
    </b-row>
    <b-row v-if="postalCode.length > 0">
      <div class="justify-content-around flex-wrap badge-group">
        <b-badge
          style="
            width: 40px;
            color: #7b7878;
            background-color: rgba(227, 228, 230, 0.8);
            height: 25x;
          "
          v-for="p in postalCode"
          :key="p"
        >
          {{ p }}
        </b-badge>
      </div>
    </b-row>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as XLSX from "xlsx";
import SettingService from "@/services/SettingService";
import AlertService from "@/services/AlertService";

export default {
  props: {
    code: {
      type: String,
      required: true,
    },
    postalCode: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        file: null,
      },
    };
  },
  methods: {
    /**
     *
     */
    exportToExcel() {
      const fileName = "postal_code.xlsx";
      const ws = XLSX.utils.json_to_sheet(
        this.postalCode.map((d) => {
          return { codigopostal: d };
        })
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "postal");
      XLSX.writeFile(wb, fileName);
    },

    /**
     *
     */

    saveList() {
      this.isLoading = true;
      SettingService.setPostalCodeValid({
        postal_code: this.postalCode,
        code: this.code
      })
        .then(() => {
          this.isLoading = false;
          AlertService.info();
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Actualizar`,
              text: `Error al tratar de almacenar`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
        });
    },

    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();

      this.isLoading = true;

      const readtWithString = (wb, name) => {
        const elements = XLSX.utils
          .sheet_to_json(wb.Sheets[name])
          .map((item) => {
            const val = {};
            Object.keys(item).forEach((key) => {
              const key2 = key.toLowerCase().replace(/\s/g, "");
              val[key2] = String(item[key]).trim();
            });
            return val;
          });
        return elements;
      };
      const file = this.form.file;
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        this.postalCode = readtWithString(wb, wb.SheetNames[0])
          .map((p) => {
            return p.codigopostal;
          })
          .filter((p) => p);
        this.saveList();
        this.isLoading = false;
      };
      reader.readAsBinaryString(file);
    },
  },
};
</script>