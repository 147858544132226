<template>
  <b-card style="height: 600px !important; max-height: 600px !important">
    <b-card-title class="pt-1 px-2 mb-0">
      <h3>Códigos Postales</h3>
    </b-card-title>
    <b-card-body>
      <template v-if="isLoading">
        <loading
          :active="isLoading"
          :can-cancel="false"
          :is-full-page="fullPage"
        ></loading>
      </template>
      <template v-else>
        <b-tabs>
          <b-tab title="GRAN BUENOS AIRES" active>
            <UpFileExcelCodePostal code="gba" :postalCode="postalCode.gba" />
          </b-tab>
          <b-tab title="CIUDAD AUTÓNOMA DE BUENOS AIRES">
            <UpFileExcelCodePostal code="caba" :postalCode="postalCode.caba" />
          </b-tab>
          <b-tab title="LA PLATA">
            <UpFileExcelCodePostal
              code="plata"
              :postalCode="postalCode.plata"
            />
          </b-tab>
          <b-tab title="INTERIOR">
            <UpFileExcelCodePostal
              code="interior"
              :postalCode="postalCode.interior"
            />
          </b-tab>
        </b-tabs>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SettingService from "@/services/SettingService";
import UpFileExcelCodePostal from "@/views/pages/settings/components/UpFileExcelCodePostal.vue";

export default {
  name: "SettingPostalCode",
  components: {
    UpFileExcelCodePostal,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      postalCode: {
        gba: [],
        caba: [],
        plata: [],
        interior: [],
      },
    };
  },
  async created() {
    try {
      this.isLoading = true;
      let data = (await SettingService.getPostalCodeValid()).data;
      this.postalCode = data;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Configuración`,
          text: `Error al tratar de cargar información`,
          icon: "AlertOctagonIcon",
          variant: "danger",
        },
      });
    }
  },
};
</script>